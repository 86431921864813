import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`focusTrap`}</inlineCode>{` behavior and `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` hook are used prevent focus from leaving a particular element. This is useful for implementing modal dialogs: only the content within the dialog should be interactive, even though the UI underneath may still be visible.`}</p>
    <h3>{`Behavior`}</h3>
    <ul>
      <li parentName="ul">{`Activation: As soon as the focus trap is activated, it will ensure that an element within the container has focus. If it doesn't, it will focus the first focusable element within the container, or, if provided, the element indicated by the `}<inlineCode parentName="li">{`initialFocus`}</inlineCode>{` parameter (see API below).`}</li>
      <li parentName="ul">{`External focus changes: If an external cause (e.g. mouse click, scripts, or accessibility software) results in an element outside the container to be focused, focus will immediately be redirected to the last-focused element that is inside the container.`}</li>
      <li parentName="ul">{`Circular tab focus: Using the `}<inlineCode parentName="li">{`TAB`}</inlineCode>{` key on the last focusable element within the container will result in the first focusable element within the container receiving focus. Similarly, `}<inlineCode parentName="li">{`Shift+TAB`}</inlineCode>{` can be used to focus the last element from the first.`}</li>
      <li parentName="ul">{`Global: Only one focus trap can be `}<em parentName="li">{`active`}</em>{` at a time. When a focus trap is enabled, if there is already an active focus trap, it becomes suspended and pushed onto a stack. Once the newly-active focus trap is disabled, the most recently-suspended trap will reactivate. Suspended focus traps can be disabled, causing them to be removed from the stack of suspended traps.`}</li>
    </ul>
    <h3>{`Demo`}</h3>
    <p>{`To see a demo, deploy Storybook and find the `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` stories.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function showDialog() {
  const dialog = document.getElementById('myDialog')
  if (dialog instanceof HTMLElement) {
    dialog.style.display = ''
    return focusTrap(dialog)
  }
}
function hideDialog(controller: AbortController) {
  document.getElementById('myDialog')?.style.display = 'none'
  controller.abort()
}
const dialogController = showDialog()

// later
if (dialogController) {
  hideDialog(controller)
}
`}</code></pre>
    <h3>{`API`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`focusTrap`}</inlineCode>{` function takes the following arguments.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`container`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`HTMLElement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When active, only elements within this container (along with the container itself) can be focused.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialFocus`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`HTMLElement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the element which will receive focus when the focus trap is activated. Defaults to the first tabbable element inside the container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`signal`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`AbortSignal?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional abort signal to control the focus trap. If one is not provided, an `}<inlineCode parentName="td">{`AbortController`}</inlineCode>{` will be returned, which can be used to disable the focus trap.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Return value`}</h4>
    <p>{`If the `}<inlineCode parentName="p">{`signal`}</inlineCode>{` argument is omitted, `}<inlineCode parentName="p">{`focusTrap()`}</inlineCode>{` will return an `}<inlineCode parentName="p">{`AbortController`}</inlineCode>{`. This object has an `}<inlineCode parentName="p">{`abort()`}</inlineCode>{` method, which can be called to disable the focus trap.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Focus management is an important consideration for accessible applications. Sometimes poor focus management can make certain tasks impossible to complete for users not using a mouse. To learn more, read the `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices/#kbd_focus_discernable_predictable"
        }}>{`ARIA guidelines for keyboard focus`}</a>{`.`}</li>
      <li parentName="ul">{`Only activate a focus trap if all UI outside of the trap container should be inert (non-interactive).`}</li>
      <li parentName="ul">{`Avoid situations where multiple focus traps may be active (e.g. dialogs that open more dialogs). This behavior handles those situations, but the pattern may indicate poor UX.`}</li>
    </ul>
    <h3>{`A note on performance`}</h3>
    <p>{`When focus trap is activated, it must perform `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/docs/insights/browser-reflow"
      }}>{`reflow`}</a>{` to discover focusable elements. Use caution not to rapidly enable and disable focus traps.`}</p>
    <h2>{`useFocusTrap hook`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` hook is used to achieve focus trapping for React components. The hook returns a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` that must be applied to the focus trap container. The hook also returns a ref that can be used to indicate the initially-focused element when a focus trap is activated.`}</p>
    <p>{`The focus trap can be disabled in two ways:`}</p>
    <ol>
      <li parentName="ol">{`Simply do not render the component. When a component that uses focus trapping is unmounted, its focus trap will be aborted automatically.`}</li>
      <li parentName="ol">{`Pass `}<inlineCode parentName="li">{`disabled: true`}</inlineCode>{` to the settings argument of `}<inlineCode parentName="li">{`useFocusTrap`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` hook also has an additional setting, `}<inlineCode parentName="p">{`restoreFocusOnCleanUp`}</inlineCode>{`. If this is set to true, when the hook is either disabled (called with `}<inlineCode parentName="p">{`disabled: true`}</inlineCode>{` or unmounted), we will attempt to re-focus the element that was focused immediately before the focus trap was enabled.`}</p>
    <h3>{`Using your own refs`}</h3>
    <p>{`If you would like to use your own refs, you can pass them into the hook as part of the settings object (see the interface below).`}</p>
    <h3>{`useFocusTrap usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export const FocusTrapExample = () => {
  const {containerRef} = useFocusTrap()
  return (
    <div ref={containerRef as React.RefObject<HTMLDivElement>}>
      <Button>Apple</Button>
      <Button>Banana</Button>
      <Button>Cantaloupe</Button>
    </div>
  )
}
`}</code></pre>
    <h3>{`FocusTrapHookSettings interface`}</h3>
    <p><inlineCode parentName="p">{`FocusTrapHookSettings`}</inlineCode>{` has the following properties:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`containerRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If provided, this will be the ref used to access the focus trap container. Otherwise, this hook will create the ref for you and return it. In both cases, the ref must be provided to the container element's JSX.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialFocusRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If provided, this will be the ref used to access the element that should receive initial focus when the focus trap is activated. Otherwise, this hook will create the ref for you and return it. If unused, the first tabbable element inside the container will be focused.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If true, the previously-established focus trap for this container will be aborted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`restoreFocusOnCleanUp`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If true, attempt to restore focus to the previously-focused element when the trap is disabled or unmounted.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      